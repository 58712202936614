import { graphql, Link, withPrefix } from 'gatsby';
import * as React from 'react';

import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { Location, SiteMetadata } from '../models';
import Seo from '../components/seo'

interface PageQueryResult {
  site: SiteMetadata;
  markdownRemark: MarkdownRemark;
  allMarkdownRemark: {
    nodes: MarkdownRemark[];
  };
}

interface MarkdownRemark {
  id: string;
  excerpt: string;
  html: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    title: string;
    feed: string;
  };
}

interface Props {
  data: PageQueryResult;
}

const LegalPostTemplate: React.FunctionComponent<Props> = ({ data }: Props) => {
    const post = data.markdownRemark;
  
    return (
      <div id="main_content">
        <Seo title={post.frontmatter.title}/>
        <Navbar dark={true} />
        <section className="page-banner">
          <div className="container">
            <div className="page-title-wrapper">
              <h1 className="page-title">{post.frontmatter.title}</h1>
              <ul className="bradcurmed">
                <li>
                  <Link to="/">
                      Home
                  </Link>              
                </li>
                <li>{post.frontmatter.feed}</li>
              </ul>
  
            </div>
          </div>
  
          <svg
            className="circle"
            data-parallax='{"x" : -200}'
            xmlns="http://www.w3.org/2000/svg"
            width="950px"
            height="950px"
          >
            <path
              fillRule="evenodd"
              stroke="rgb(250, 112, 112)"
              strokeWidth="100px"
              strokeLinecap="butt"
              strokeLinejoin="miter"
              opacity="0.051"
              fill="none"
              d="M450.000,50.000 C670.914,50.000 850.000,229.086 850.000,450.000 C850.000,670.914 670.914,850.000 450.000,850.000 C229.086,850.000 50.000,670.914 50.000,450.000 C50.000,229.086 229.086,50.000 450.000,50.000 Z"
            />
          </svg>
  
          <ul className="animate-ball">
            <li className="ball"></li>
            <li className="ball"></li>
            <li className="ball"></li>
            <li className="ball"></li>
            <li className="ball"></li>
          </ul>
        </section>
  
        <section className="blog-single">
          <div className="container pb-120">
            <div className="row">
              <div className="col-lg-12">
                <article className="post post-signle">
                  <div className="blog-content">
                    <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  };
  
  export default LegalPostTemplate;
  
  export const pageQuery = graphql`
    query LegalPostBySlug($id: String!) {
      site {
        siteMetadata {
          title
        }
      }
      markdownRemark(id: { eq: $id }) {
        id
        excerpt(pruneLength: 160)
        html
        frontmatter {
          title
          feed
        }
      }
    }
  `;

